body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.icon-download {
  cursor: pointer;
}

// .mat-chip-list-wrapper {
//    max-width: 500px !important;
// }

.state-cell {
  min-width: 210px !important;
}

.mat-dialog-container {
  min-width: 100% !important;
}

.disable.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.54) !important;
}

.disable .mat-input-element:disabled {
  color: black !important;
}

.disable .mat-select-value {
  color: black !important;
}

.disable .mat-select-arrow {
  color: rgba(0, 0, 0, 0) !important;
}

.disable .mat-form-field-infix {
  cursor: unset !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.main-container {
  position: absolute;
  top: 64px;
  left: 0px;
  right: 0;
  bottom: 0;
}

.no-padding {
  header {
    width: 100%;
    height: 10vh;
    padding: 10px 15px;
    margin: 0;
  }

  .mat-dialog-container {
    padding: 0 !important;
  }

  .mat-dialog-actions {
    padding: 10px 15px;
    height: 10vh;
    max-height: 10vh;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0 !important;
    /* display: block; */
    align-items: center;
    flex-wrap: unset;
  }

  .mat-dialog-content {
    margin: 0;
    width: 100%;
  }
}

ins {
  color: black;
  background: #bbffbb;
}

del {
  color: black;
  background: #ffbbbb;
}

.mat-tooltip {
  white-space: pre-line; // Allow line break
}