
// COLORS

// GRID
$grid-width:                        115rem;
$grid-gutter-vertical:              8rem;
$grid-gutter-vertical-sm:           6rem;
$grid-gutter-horizontal:            5rem;

// FONT SIZE
$font-default-fontsize:             1.6rem;
