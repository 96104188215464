.select-parenthesis .mat-select-value {
    display: none !important;
}

.select-parenthesis .mat-select-arrow-wrapper {
    display: none !important;
}

.select-parenthesis {
    display: inline;
    width: 0px !important;
}