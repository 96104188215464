.row {
  max-width: $grid-width;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: $grid-gutter-vertical;

    @include respond(tablet-portrait) {
      margin-bottom: $grid-gutter-vertical-sm;
    }
  }

  @include respond(tablet-portrait) {
    max-width: 50rem;
    padding: 0 3rem;
  }

  @include clearfix;

  [class^="col-"] {
    float: left;

    &:not(:last-child) {
      margin-right: $grid-gutter-horizontal;

      @include respond(tablet-portrait) {
        margin-right: 0;
        margin-bottom: $grid-gutter-vertical-sm;
      }
    }

    @include respond(tablet-portrait) {
      width: 100% !important;
    }
  }

  .col-1-of-2 {
    width: calc((100% - #{$grid-gutter-horizontal}) / 2);
  }

  .col-1-of-3 {
    width: calc((100% - 2*#{$grid-gutter-horizontal}) / 3);
  }

  .col-2-of-3 {
    width: calc(2*((100% - 2*#{$grid-gutter-horizontal}) / 3))+#{grid-gutter-horizontal};
  }

  .col-1-of-4 {
    width: calc((100% - 3*#{$grid-gutter-horizontal}) / 4);
  }

  .col-2-of-4 {
    width: calc(2* ((100% - 3*#{$grid-gutter-horizontal}) / 4))+#{grid-gutter-horizontal};
  }

  .col-3-of-4 {
    width: calc(3* ((100% - 3*#{$grid-gutter-horizontal}) / 4))+#{grid-gutter-horizontal}+#{grid-gutter-horizontal};
  }
}
