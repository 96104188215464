// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
.mat-form-field,
.mat-checkbox {
  padding: 0 0.5rem;
}

th .mat-form-field{
  padding: 0;
}

snack-bar-container.error {
  background: red;
  color: white;
}

mat-form-field.icon-only {
  width: 75px !important;
}

.mat-simple-snackbar-action button {
  color: #ccc;
}

.mat-tooltip {
  font-size: 18px;
}
.mat-menu-item,
.mat-option {
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    background-color: #f6fafd !important;
  }
}
.mat-option.mat-active {
  background-color: #f6fafd !important;
}

.mat-dialog-actions {
  justify-content: space-between;
}
