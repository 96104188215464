/* From http://mcg.mbitson.com/ */
$theme-primary: #053605;

$wsreminder-primary:(
  50 : #e1e7e1,
  100 : #b4c3b4,
  200 : #829b82,
  300 : #507250,
  400 : #2b542b,
  500: var(--theme-primary, $theme-primary),
  600 : #043004,
  700 : #042904,
  800 : #032203,
  900 : #011601,
  A100 : #56ff56,
  A200 : #23ff23,
  A400 : #00ef00,
  A700 : #00d500,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #ffffff,
  )
);

$wsreminder-accent: (
  50: #e1eaf8,
  100: #b3caee,
  200: #81a7e3,
  300: #4f83d7,
  400: #2969cf,
  500: #034ec6,
  600: #0347c0,
  700: #023db9,
  800: #0235b1,
  900: #0125a4,
  A100: #ced6ff,
  A200: #9baaff,
  A400: #687fff,
  A700: #4f69ff,
  contrast: (
    50: #fff,
    100: #fff,
    200: #fff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #ffffff,
  ),
);

$wsreminder-warn: (
  50: #f9e7e0,
  100: #f0c3b3,
  200: #e69b80,
  300: #db724d,
  400: #d45426,
  500: #cc3600,
  600: #c73000,
  700: #c02900,
  800: #b92200,
  900: #ad1600,
  A100: #ffdad7,
  A200: #ffaaa4,
  A400: #ff7b71,
  A700: #ff6358,
  contrast: (
    50: #fff,
    100: #fff,
    200: #fff,
    300: #fff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);
