.badge {
  background: grey;
  padding: 5px 10px;
  color: white;
  border-radius: 15px;
  margin: 0 2px;

  &-error {
    background-color: #fc0000;
  }

  &-success {
    background-color: #3cb043;
  }
}



.session-status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 0.5rem;
  display: block;

  &-online {
    background-color: #3cb043;
  }

  &-offline {
    background-color: #fc0000;
  }

  &-official {
    background-color: #3b5999;
  }
}

