.mat-progress-bar {
  height: 10px !important;
  border-radius: 5px;

  &.full {
    .mat-progress-bar-fill::after {
      background-color: rgb(0, 160, 0);
    }

    .mat-progress-bar-buffer {
      background-color: #b0ffb0;
    }
  }

  &.half {
    .mat-progress-bar-fill::after {
      background-color: rgb(255, 217, 0);
    }

    .mat-progress-bar-buffer {
      background-color: #fffcb0;
    }
  }

  &.empty {
    .mat-progress-bar-fill::after {
      background-color: rgb(171, 0, 0);
    }

    .mat-progress-bar-buffer {
      background-color: #f7b8aa;
    }
  }
}
